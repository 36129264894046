import Style from './FaqHero.module.css'

const FaqHero = () => {
  return ( 
    <section role="banner" className={Style.faqHero}>
      <div className="container">
        <h1>Frequently<br/>Asked<br/>Questions</h1>
      </div>
    </section>
   );
}
 
export default FaqHero;