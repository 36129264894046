import Style from './Connect.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareInstagram, faFacebook, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Connect = () => {
  return ( 
    <section className={Style.connect} role="complementary">
      <div className="container text-md-center">
        <p>Have questions or just want to share your dog's story? Your connection means the world to us. Reach out to Willow & I for a warm and caring conversation. Our team is here for you, ensuring your satisfaction and your dog's happiness.</p>
        <a className={Style.mailLink} href="mailto:hello@willowandi.co">hello@willowandi.co</a>
        <div className="text-center">
          <span className={Style.socials}>Connect with us on Social</span>
          <div className="d-inline-block">
            <a className={Style.icon} target='_blank' rel="noopener noreferrer" href="https://facebook.com/willowandi.co">
              <span className={Style.visuallyHidden}>Facebook</span>
              <FontAwesomeIcon icon={faFacebook} className={Style.icon} />
            </a>
            <a className={Style.icon} target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/@willowandi">
              <span className={Style.visuallyHidden}>Youtube</span>
              <FontAwesomeIcon icon={faYoutube} className={Style.icon} />
            </a>
            <a className={Style.icon} target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/willowandi.co/?igsh=YXhmb3I3eHBzNHRm&utm_source=qr&fbclid=IwZXh0bgNhZW0CMTAAAR0SEojgvz2T7pGmYzlfanM5fJeuRN7eizUItEGV2qyDcoqI7vE7u-n7wFs_aem_AYajOg93r-htIwQDXpGeNIgZRRnQ8iSDPvinz0cxBABJq7hXr8X25Fp135Gb-IcCUTB1F-3xKE9PEP1Va6ft2OvN">
              <span className={Style.visuallyHidden}>Instagram</span>
              <FontAwesomeIcon icon={faSquareInstagram}  className={Style.icon} />
            </a>
            <a className={Style.icon} target='_blank' rel="noopener noreferrer" href="https://www.tiktok.com/@willowandico">
              <span className={Style.visuallyHidden}>Tiktok</span>
              <FontAwesomeIcon icon={faTiktok}  className={Style.icon} />
            </a>
          </div>
        </div>
      </div>
    </section>
   );
}

export default Connect;