import Style from './Faq.module.css'
import FaqHero from '../components/faq/FaqHero';
import Questions from '../components/faq/Questions';
import ContactForm from '../components/faq/ContactForm';
import faqData from '../data/faqData.json'

const Faq = () => {
  return ( 
    <div className={Style.faq}>
      <FaqHero />
      <div className={Style.questions}>
        <Questions title="Our Products" questions={faqData.productRelated}/>
        <Questions title="Orders" questions={faqData.orderRelated}/>
      </div>
      <ContactForm />
    </div>
   );
}
 
export default Faq;