import Style from "./Product.Preview.module.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ProductPreview = (props) => {
  return ( 
    <div className={Style.productPreview}>
      <img src={props.image.image} alt={props.image.alt} />
      <h1>{props.name}</h1>
      <Link className={Style.button} to={"/product/" + props.id}>VIEW</Link>
    </div>
   );
}
 
export default ProductPreview;