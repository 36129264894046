import Style from './Home.module.css';
import HomeHero from '../components/home/HomeHero';
import BullySticks from '../components/home/BullySticks';
import Friends from '../components/home/Friends';
import Usp from '../components/home/Usp';

const Home = () => {
  return ( 
    <div className={Style.home}>
      <HomeHero />
      <Usp />
      <BullySticks />
      <Friends />        
    </div>
   );
}
 
export default Home;