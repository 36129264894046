import Style from './Product.module.css'
import productData from '../data/productData.json'
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ExpandableRow from '../components/faq/ExpandableRow'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import { useEffect, useState } from 'react'

const Product = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)

  const updateIsDesktop = () => {
    const desktop = window.innerWidth >= 992
    setIsDesktop(desktop)
  }

  useEffect(() => {
    window.addEventListener('resize', updateIsDesktop)
    return () => {
      window.removeEventListener('resize', updateIsDesktop)
    }
  }, [])

  const { id } = useParams()
  const product = productData.products.find((item) => item.id === id)
  if (!product) {
    return <Redirect to="/404" />
  }

  return (
    <section className={Style.product}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 pe-md-5 mb-5">
            <div className={Style.sliderContainer}>
              <Carousel 
                emulateTouch={true}
                infiniteLoop={true}
                showArrows={false}
                interval={5000}
                showStatus={false}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={25}
                renderThumbs={(children) => {
                  return isDesktop ? children : null
                }}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  
                  if (isDesktop) {
                    return null
                  }
                  
                  const circleStyle = {
                    display: 'inline-block',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    marginRight: '15px',
                    cursor: 'pointer',
                    background: isSelected ? 'var(--color-teal)' : 'transparent',
                    border: `1px solid var(--color-teal)`
                  }
                  
                  return (
                    <span
                      style={circleStyle}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      role="button"
                      tabIndex={0}
                      aria-label={`${label} ${index + 1}`}
                    />
                  )
                }}
                >
                {product.images.map((image, index) => {
                  return <div key={'image' + index}>
                    <div className='py-5 py-lg-0'>
                      <img src={image.image} alt={`Slide ${index + 1}: ${image.alt}`} />
                    </div>
                  </div>
                })}
                  
              </Carousel>
            </div>
          </div>
          <div className="col-12 col-md-7 ps-md-5">
            <h1 role="banner">{product.longName}</h1>
            {product.information.map((section, index) => {
              return <div key={'row' + index} className={Style.informationRow}>
                <ExpandableRow title={section.title}>
                  {section.items.map((item, itemIndex) => {
                    return <div className={Style.informationItem} key={'item' + itemIndex}>
                      {item.title && <span>{item.title} </span>}
                      <div className={Style.informationText} dangerouslySetInnerHTML={ { __html: item.text } }></div>
                    </div>
                  })}
                </ExpandableRow>
              </div>
              })}
            <div role="region" aria-label="Amazon Product Link" className="text-center text-md-start">
              <a role="button" target="_blank" rel="noopener noreferrer" href={product.amazonLink} className="button button-big button-reversed mt-5">BUY ON AMAZON</a>
            </div>
          </div>
        </div>
      </div>
    </section>
   );
}

export default Product;