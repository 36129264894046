import Style from './GiveawayPolicy.module.css'

const GiveawayPolicy = () => {
  return ( 
    <section className={Style.giveawayPolicy}>
      <div className="container">
        <h1>Giveaway Policy</h1>
        <section>
          <h2>Introduction</h2>
          <p>By providing your email address, you agree to the terms outlined in this policy, including how we handle your information in relation to our discount code offer and monthly giveaway.</p>
        </section>
        <section>
          <h2>Collection and Use of Information</h2>
          <p>When you enter your email address on this landing page, we collect this information to:</p>
          <ul>
            <li>Send you an email with a 20% discount code for your next order.</li>
            <li>Automatically enroll you in our monthly giveaway for a chance to win free bully sticks.</li>
            <li>Contact you if you are selected as a giveaway winner.</li>
            <li>Send occasional marketing and promotional emails related to Willow & I products.</li>
          </ul>
        </section>
        <section>
          <h2>Giveaway Terms</h2>
          <ul>
            <li>By submitting your email, you are automatically enrolled in our monthly giveaway.</li>
            <li>One winner is selected during the first week of each month, between Monday and Friday, and will be notified via the provided email address.</li>
            <li>Winners must respond within 7 days  to claim their prize. If no response is received within this period, a new winner may be selected.</li>
            <li>Prizes are not transferable and cannot be exchanged for cash or other items.</li>
          </ul>
        </section>
        <section>
          <h2>Email Communication</h2>
          <p>By submitting your email address, you consent to receive marketing communications from Willow & I. You may opt out at any time by clicking the 'unsubscribe' link in our emails.</p>
        </section>
        <section>
          <h2>Privacy</h2>
          <p>We respect your privacy and are committed to protecting your personal information. We do not sell, trade, or rent your email address to third parties. For more details on how we handle your data, please refer to our <a target='_blank' rel="noopener noreferrer" href="/privacy-policy">Privacy Policy</a></p>
        </section>
        <section>
          <h2>Data Security</h2>
          <p>We use reasonable security measures to protect your information from unauthorized access or disclosure. However, please note that no data transmission over the internet is 100% secure.</p>
        </section>
        <section>
          <h2>Changes to This Policy</h2>
          <p>We may update this policy occasionally. Any changes will be posted on this page with an updated effective date.</p>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>If you have any questions about this policy, please contact us at <a href="mailto:hello@willowandi.co">hello@willowandi.co</a>.</p>
        </section>
      </div>
    </section>
   );
}
 
export default GiveawayPolicy;