import Style from './Contact.module.css';
import ContactHero from '../components/contact/ContactHero';
import Connect from '../components/contact/Connect';
import Subscribe from '../components/contact/Subscribe';

const Contact = () => {
  return ( 
    <div className={Style.contact}>
      <ContactHero />
      <Connect />
      <Subscribe />
    </div>
   );
}
 
export default Contact;