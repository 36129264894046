import Style from './AboutMadelene.module.css'

const AboutMadelene = () => {
  return ( 
    <section className={Style.aboutMadelene} aria-label="About Madelene">
      <img className={Style.abstractBg} src="images/abstractshapedarkgreen-2@2x.png" alt="Decorative background shape" />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 px-5 px-md-0 mb-5 mb-md-0">
            <img src="images/1.jpg" alt="Portrait of Madelene and her dogs" />
          </div>
          <div className="col-12 col-md-7 offset-md-1 mt-4 md-md-0">
            <p>Early on, we realized the crucial role that quality ingredients play in promoting overall health. Ember, Kajsa's dog, was diagnosed with arthritis before she was even a year old, and the vet doubted she would make it past three. Now almost five years old, Ember shows no signs of pain and lives her best life. We firmly believe that her improved nutrition, including healthy treats, played a vital role in her well-being.</p>
            <p>Meanwhile, when Nalu and Koa were young, Madelene discovered that long-lasting chews were a game-changer during their tumultuous puppy years. Aiding with teething and providing comfort in stressful environments. But surprisingly, there are many chews and bones available on the market that are too hard on teeth or that easily splinter. Finding safe chews became the number one mission.</p>
            <p>With Willow & I, we aim to make it easy for you and only introduce products that are truly healthy for dogs with clear and transparent ingredients. We want to be your go-to source for transparency and trust.</p>
          </div>
        </div>
        <div className="row">
          <div className={`mt-5 col-12 col-xl-8 offset-xl-2 ${Style.tagline}`}>
            <h3>No Compromises, no Questionable Ingredients.</h3>
          </div>
        </div>
      </div>
    </section>
   );
}
 
export default AboutMadelene;