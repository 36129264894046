import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Products from './pages/Products';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import Product from './pages/Product';
import WinGift from './pages/WinGift';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GiveawayPolicy from './pages/GiveawayPolicy';
import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTopOnMount />
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/product/:id">
              <Product />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/faq">
              <Faq />
            </Route>
            <Route path="/win-gift">
              <WinGift />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms-of-use">
              <TermsOfUse />
            </Route>
            <Route path="/giveaway-policy">
              <GiveawayPolicy />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
