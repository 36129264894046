import Style from './Subscribe.module.css'

const Subscribe = () => {
  return ( 
    <section className={Style.subscribe} aria-label='Subscribe section'>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2 id="mc_embed_signup_heading" className='mb-4'>Subscribe to Our Email Newsletter</h2>
            <p>Be the first to know! Sign up for emails to stay in the loop about the latest news.</p>
            <div id="mc_embed_shell">
              <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
              <section aria-label="Email Subscription Form" id="mc_embed_signup">
                <form action="https://willowandi.us17.list-manage.com/subscribe/post?u=24be5dd1ccaac8e0c1fad5a29&amp;id=83ab82e317&amp;f_id=00d6c2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" rel="noopener noreferrer" aria-label="Email Subscription Form">
                  <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                      <label htmlFor="mce-FNAME">Name</label>
                      <input type="text" name="FNAME" className="text" id="mce-FNAME" />
                    </div>
                    <div className="mc-field-group">
                      <label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span></label>
                      <input 
                        type="email" 
                        name="EMAIL" 
                        className="required email" 
                        id="mce-EMAIL" 
                        required 
                        aria-required="true"
                        aria-label="Your Email Address"
                      />
                    </div>
                    <div id="mce-responses" className="clear">
                      <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                      <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                    </div>
                    <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                      <input type="text" name="b_24be5dd1ccaac8e0c1fad5a29_83ab82e317" tabIndex="-1" />
                    </div>
                    <div className="clear text-center text-md-start">
                      <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="SUBMIT" aria-label="Subscribe Button" />
                    </div>
                  </div>
                </form>
              </section>
              <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
              <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
                (function($) {
                  window.fnames = new Array();
                  window.ftypes = new Array();
                  fnames[1]='FNAME';
                  ftypes[1]='text';
                  fnames[0]='EMAIL';
                  ftypes[0]='email';
                  fnames[2]='LNAME';
                  ftypes[2]='text';
                  fnames[3]='ADDRESS';
                  ftypes[3]='address';
                  fnames[4]='PHONE';
                  ftypes[4]='phone';
                  fnames[5]='BIRTHDAY';
                  ftypes[5]='birthday';
                }(jQuery));
                var $mcj = jQuery.noConflict(true);
              `}}></script>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
 
export default Subscribe;