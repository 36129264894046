import Style from './Friends.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Friends = () => {
  return ( 
    <section className={Style.friends} aria-labelledby='friends-title'>
      <div className="container text-center text-lg-start">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 align-middle">
            <h2 id='friends-title'>Two Best Friends and Their Pups:</h2>
            <h3>Redefining Industry Standards</h3>
            <div className="d-none d-lg-block">
              <p>We are a women-owned brand born from the frustration of misleading pet products and the lack of transparency when it comes to ingredients. Willow & I stand against deception and prioritize the well-being of dogs.</p>
              <Link className="button" to="/about">OUR STORY</Link>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <img src="/images/kajsa@2x.png" alt="Portrait of Kajsa" />
            <img src="/images/madelene@2x.png" alt="Portrait of Madelene" />
          </div>
          <div className="col-12 d-lg-none mt-5">
            <p>We are a women-owned brand born from the frustration of misleading pet products and the lack of transparency when it comes to ingredients. Willow & I stand against deception and prioritize the well-being of dogs.</p>
            <Link className="button" to="/about">OUR STORY</Link>
          </div>
        </div>
      </div>
    </section>
   );
}
 
export default Friends;