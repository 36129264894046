import Style from './AboutKajsa.module.css'

const AboutKajsa = () => {
  return ( 
    <section className={Style.aboutKajsa} aria-labelledby='about-kajsa-title'>
      <img className={`d-none d-md-block ${Style.abstractBg}`} src="images/abstractshapedarkgreen-1@2x.png" alt="Decorative background shape" />
      <img className={`d-md-none ${Style.abstractBgMobile}`} src="images/abstract-shape-dark-green 1.png" alt="Decorative background shape" />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 pe-md-5">
            <h2 id='about-kajsa-title' >Willow & I was founded in 2023 by two best friends, Kajsa and Madelene.</h2>
            <p>In a world full of dog treats, it's easy to feel lost, wondering what ingredients are good for our dogs. We've been there too, asking ourselves: What's in these treats? Are they safe?</p>
            <p>Both of us have dogs who mean the world to us: Madelene has two energetic Siberian Huskies-Nalu and Koa, and Kajsa has a loving mutt from Mexico named Ember.</p>
            <p>Since their puppy days, we've centered all our free time around our furry companions, exploring dog-friendly vacations (shoutout to Cruise America!), Airbnb's, campgrounds, and hikes across the country. Nothing brings us more joy than watching our dogs frolic in the snow or on the beach.</p>
          </div>
          <div className="col-12 col-md-6 px-5 mt-5 mt-md-0 mb-5 mb-md-0 text-center">
            <img className="d-none d-md-block" src="images/kajsa.jpg" alt="Portrait of Kajsa" />
            <img className="d-md-none" src="images/kajsa-bjoern-dschmitz-240228-9311 1.jpg" alt="Portrait of Kajsa" />
          </div>
        </div>
      </div>
    </section>
   );
}
 
export default AboutKajsa;