import Style from './AboutHero.module.css'

const AboutHero = () => {
  return ( 
    <header role="banner">
      <div className={Style.aboutHero}>
        <div className="container">
          <div className="row d-none d-md-block">
            <div className="col-6 col-xl-5">
              <h1>Founded by two dog moms on a mission:</h1>
              <h2>genuine pet well-being and trust</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={`d-md-none ${Style.mobileText}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Founded by two dog moms on a mission:</h1>
              <h2>genuine pet well-being and trust</h2>
            </div>
          </div>
        </div>
      </div>
    </header>
   );
}
 
export default AboutHero;