import Style from './HomeHero.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const HomeHero = () => {
  return ( 
    <header className={Style.homeHero} role='banner'>
      <div className="container text-start">
        <h1>No hidden words,<br/><span className={Style.heavy}>just honest love.</span></h1>
        <p>Nurturing bonds with wholesome dog treats & chews.</p>
        <div className="text-center text-lg-start">
          <Link className="button" to="/products">SHOP NOW</Link>
        </div>
      </div>
    </header>
   );
}
 
export default HomeHero;