import Style from './Questions.module.css'
import ExpandableRow from './ExpandableRow';

const Questions = (props) => {
  return ( 
    <section aria-labelledby="questions-heading" className={Style.questions}>
      <div className="container">
        <h2 id="questions-heading">{props.title}</h2>
        <ul>
          {props.questions.map((item, index) => (
            <li key={index}>
              <ExpandableRow title={item.question}>
                <p dangerouslySetInnerHTML={ { __html: item.answer } }></p>
              </ExpandableRow>
            </li>
          ))}
        </ul>
      </div>
    </section>
   );
}
 
export default Questions;