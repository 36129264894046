import Style from './NotFound.module.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = () => {
  return ( 
    <section className={Style.notFound} role="banner">
      <div className="container-fluid">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-5 col-12">
            <div className="col-xl-8 offset-xl-4 col-md-10 offset-md-2 col-12 text-center text-md-start py-5 py-md-0">
              <h1>ERROR 404</h1>
              <h2>PAGE NOT FOUND</h2>
              <p>Sorry we did not find that page. Try searching again or go to <Link to="/">willowandi.co.</Link></p>
            </div>
          </div>
          <div className="col-md-7 px-0 col-12">
            <img src="images/404.jpg" alt="Dog with a cable in its mouth, representing a 404 error." />
          </div>
        </div>
      </div>
    </section>
   );
}
 
export default NotFound;