import Style from './TermsOfUse.module.css'

const TermsOfUse = () => {
  return ( 
    <section className={Style.termsOfUse}>
      <div className="container">
        <h1>Terms of Use</h1>
        <section>
          <p>The following terms and conditions (the “Terms”) govern your use of the Willow & I website, available at <a href="https://willowandi.co">https://willowandi.co</a> (the “Website”), including any content, features, or functionality made available through the Website. The Website is made available by Willow & I Inc. (“Willow & I” or “we” or “us” or “our”). We may modify these Terms at any time without notice by posting such changes on the Website. BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO THESE TERMS AS APPLIED TO YOUR USE OF THE WEBSITE. If you do not agree to these Terms, you may not access or otherwise use the Website.</p>
          <p>Please note that when using certain services, you may be subject to any additional terms applicable to such services that may be posted on our Website or otherwise made available through the Website from time to time.</p>
        </section>
        <section>
          <h2>Proprietary Rights</h2>
          <p>All content on the Website, including pricing, promotional materials, audio, photographs, illustrations, graphics, video, and text, as well as the design and organization of the Website, are the property of Willow & I or our third-party licensors. Your use of the Website does not grant you ownership of any content, code, or materials you may access through the Website.</p>
          <p>No permission or license is granted or implied for the use of trademarks, logos, or service marks displayed on the Website, collectively referred to as the “Trademarks.” Any third-party names and logos may also be trademarks of their respective owners and cannot be used without prior written permission.</p>
        </section>
        <section>
          <h2>Limited License</h2>
          <p>You may access and view the content on the Website on your computer or other device. Unless otherwise indicated in these Terms or on the Website, you may make single copies or prints of the content for your personal, internal use only. The services offered on or through the Website (collectively, the “Website Services”) are intended solely for your personal, non-commercial use.</p>
        </section>
        <section>
          <h2>Product Descriptions</h2>
          <p>Prices for our products are subject to change without notice. Certain products or services may be available exclusively online through the Website and may have limited quantities. Such products are subject to return or exchange only according to our Satisfaction Guarantee.</p>
          <p>While we strive for accuracy, we do not warrant that product descriptions, images, ingredient lists, or other content on the Website are accurate, complete, reliable, current, or error-free. If a product you receive is not as described, your sole remedy is to return the product in accordance with our return policy.</p>
        </section>
        <section>
          <h2>User-Submitted Materials</h2>
          <p>You are solely responsible for any content you upload, post, publish, transmit, or display through the Website, including reviews or comments. We do not solicit or wish to receive any confidential or proprietary information from you. Any information or materials you submit to us will be considered non-confidential. By submitting materials, you grant Willow & I and our affiliates the right to use, reproduce, modify, and distribute such materials for any purpose.</p>
        </section>
        <section>
          <h2>Prohibited Use</h2>
          <p>Unless explicitly indicated, any commercial distribution or exploitation of the Website, or its content, is strictly prohibited without prior written permission from Willow & I. You may not modify, publish, distribute, or create derivative works from any content on the Website.</p>
          <p>You represent that you are not located in a country that is subject to a U.S. government embargo and will not use the Website for any unlawful purpose.</p>
        </section>
        <section>
          <h2>Disclaimer of Warranties</h2>
          <p>The Website and its content are provided on an “as is” and “as available” basis. Willow & I makes no warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that the Website will be uninterrupted or error-free.</p>
        </section>
        <section>
          <h2>Limitation of Liability</h2>
          <p>In no event shall Willow & I, our affiliates, or any of our directors, officers, employees, or agents be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of the Website or any content or services provided therein.</p>
        </section>
        <section>
          <h2>Indemnification</h2>
          <p>You agree to indemnify and hold harmless Willow & I and our affiliates from any claims, liabilities, costs, and expenses arising from your use of the Website or your violation of these Terms.</p>
        </section>
        <section>
          <h2>Governing Law</h2>
          <p>These Terms shall be governed by the laws of the State of California. You agree that any disputes arising under these Terms will be subject to the exclusive jurisdiction of the courts located in California.</p>
        </section>
        <section>
          <h2>Changes to Terms</h2>
          <p>Willow & I reserves the right to change these Terms at any time. Changes will be effective when posted on the Website. Your continued use of the Website after any changes constitutes acceptance of the new Terms.</p>
        </section>
        <section>
          <h2>Contact Information</h2>
          <p>For any questions or concerns about these Terms, please contact us at <a href="mailto:hello@willowandi.co">hello@willowandi.co</a>.</p>
        </section>
      </div>
    </section>
   );
}
 
export default TermsOfUse;