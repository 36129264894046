import Style from './About.module.css';
import AboutHero from '../components/about/AboutHero';
import AboutKajsa from '../components/about/AboutKajsa';
import AboutMadelene from '../components/about/AboutMadelene';

const About = () => {
  return ( 
    <div className={Style.about}>
      <AboutHero />
      <AboutKajsa />
      <AboutMadelene />
    </div>
   );
}
 
export default About;