import Style from './ExpandableRow.module.css'
import { useState, useRef, useEffect } from 'react';

const ExpandableRow = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const contentRef = useRef(null)

  useEffect(() => {
    if (isExpanded) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`
    } else {
      contentRef.current.style.height = '0px'
    }
  }, [isExpanded])

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleKeyPress = event => {
    if (isFocused && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault()
      toggleExpansion()
    }
  }

  return ( 
    <div className={Style.expandableRow} role="region" aria-labelledby={`expandable-row-title-${props.title.replace(/\s/g, '-')}`}>
      <div 
        className={`row ${Style.accordion}`}
        role='button'
        aria-expanded={isExpanded}
        onClick={toggleExpansion}
        onKeyDown={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex={0}
      >
        <div className="col-8">
          <p className={Style.title}>{props.title}</p>
        </div>
        <div className="col-4 text-end">
          <p className={Style.icon}>
            {isExpanded ? '-' : '+'}
          </p>
        </div>
      </div>
      <div 
        ref={contentRef} 
        className={`${Style.content} ${isExpanded ? Style.expanded : Style.collapsed}`}
        aria-hidden={!isExpanded}
      >
        <div id={`expandable-row-title-${props.title.replace(/\s/g, '-')}`} className={Style.contentText}>
          {props.children}
        </div>
      </div>
      <hr className={Style.separator}/>
    </div>
   );
}
 
export default ExpandableRow;