import Style from './BullSticks.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const BullySticks = () => {
  return ( 
    <section className={Style.bullySticks} aria-labelledby='bullysticks-title'>
      <div className="container">
        <h2 id='bullysticks-title'>Why <span className={Style.highlight}>Bully Sticks</span> are <strong>irresistible</strong> to dogs</h2>
        <div className={Style.items}>
          <div className={Style.item}>
            <img src="images/willowi-element-health-1@2x.png" alt="Decorative element" />
            <h3>Rich flavor & nutrient dense</h3>
          </div>
          <div className={Style.item}>
            <img src="images/willowi-element-beef-1@2x.png" alt="Decorative element" />
            <h3>Made of 100% organ meat</h3>
          </div>
        </div>
        <div className={Style.items}>
          <div className={Style.item}>
            <img src="images/willowi-element-smile-1@2x.png" alt="Decorative element" />
            <h3>Promotes dental health</h3>
          </div>
          <div className={Style.item}>
            <img src="images/willowi-element-balance-1@2x.png" alt="Decorative element" />
            <h3>Mentally engaging & eases stress</h3>
          </div>
        </div>
        <Link className="button d-none d-md-inline-block" to="/products">LEARN MORE</Link>
      </div>
      <div className={Style.bg}>
        <Link className="button d-md-none" to="/products">LEARN MORE</Link>
      </div>
    </section>
   );
}
 
export default BullySticks;