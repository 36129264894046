import Style from './Usp.module.css'

const Usp = () => {
  return ( 
    <section className={Style.usp} aria-labelledby='usp-title'>
      <div className="container text-center text-md-start">
        <h2 id='usp-title'>What makes us <span className={Style.highlight}>different?</span></h2>
        <ul>
          <li>
            <img src="images/willowi-element-paws-1@2x.png" alt="Decorative list bullet" />
            <div>
              <h3>Dog-approved ingredients</h3>
              <p>We take no shortcuts in crafting our <strong>healthy treats.</strong></p>
            </div>
          </li>
          <li>
            <img src="images/willowi-element-joy-1@2x.png" alt="Decorative list bullet" />
            <div>
              <h3>Clarity & honesty</h3>
              <p>No confusing marketing jargon, just <strong>clear communication.</strong></p>
            </div>
          </li>
          <li>
            <img className='d-inline-block' src="images/willowi-element-heart-3@2x.png" alt="Decorative list bullet" />
            <div>
              <h3>Female owned</h3>
              <p>Two dog moms on a mission to create a <strong>positive impact.</strong></p>
            </div>
          </li>
        </ul>
      </div>
      <div className={Style.bg}></div>
    </section>
   );
}
 
export default Usp;