import Style from './ContactHero.module.css'

const ContactHero = () => {
  return ( 
    <header role="banner" className={Style.contactHero}>
      <div className="container">
        <h1>
          Contact Us
        </h1>
      </div>
    </header>
   );
}
 
export default ContactHero;