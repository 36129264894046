import Style from './Products.module.css';
import productData from '../data/productData.json'
import faqData from '../data/faqData.json'
import Questions from '../components/faq/Questions'
import ProductPreview from '../components/products/ProductPreview';

const Products = () => {
  return ( 
    <div className={Style.products} role="region" aria-label="Product Information">
      <div className="container">
        <h1 role="banner">Bully Sticks</h1>
        <p className={Style.ingress}>At Willow & I, quality matters just as much to us as it does to you! We carefully choose top-quality beef pizzle from trusted sources in South America and Europe. Did you know dogs love organ meat because it's so nutritious? That's why they can't resist Bully Sticks! They are a good protein source which helps to repair muscle while providing essential nutrients like Vitamin B6, zinc, and calcium. Plus, they help keep teeth clean and breath fresh. Our Bully Sticks are 100% natural and single-ingredient, giving your dog a tasty, long-lasting treat completely free from additives or chemicals.</p>
        <div className="row">
          {productData.products.map((product) => (
            <div className="col-12 col-sm-6 mt-5 mb-5" key={product.id}>
              <ProductPreview id={product.id} name={product.name} longName={product.longName} image={product.images[0]}/>
            </div>
          ))}
        </div>
        <div className="text-center mt-3">
          <span className={Style.tagline}>A Simple Love Gesture They Deserve</span>
        </div>
        </div>
      <Questions title="Frequently Asked Questions" questions={faqData.productRelated} />
    </div>
   );
}
 
export default Products;