import Style from './ContactForm.module.css'

const ContactForm = () => {
  return ( 
    <section className={Style.contactForm} aria-labelledby="contact-form-heading">
      <div className="container">
        <h2 id="contact-form-heading">Can't find what you're looking for? Let us know:</h2>
        <form 
          name="contact" 
          method="post"
          aria-label="Contact Form"
        >
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor="name">Name</label>
          <div className="row">
            <div className="col-12 col-sm-8">
              <input 
                type="text" 
                id="name" 
                name="name"
                required
                aria-required="true"
                aria-label="Your Name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <label htmlFor="email">Phone Number</label>
              <input 
                type="tel" 
                id="phone" 
                name="phone"
                pattern="\d{3}[-\s]?\d{3}[-\s]?\d{4}"
                required 
                aria-required="true"
                aria-label="Your Phone Number"
              />
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email" 
                name="email"
                required 
                aria-required="true"
                aria-label="Your Email Address"
              />
              <label htmlFor="message">How can we help?</label>
              <textarea
                id="message" 
                name="message"
                required 
                aria-required="true"
                aria-label="Message"
              /> 
            </div>
          </div>
          <div className="text-center text-sm-start">
            <button type="submit">SUBMIT</button>
          </div>
        </form>
      </div>
    </section>
   )
}
 
export default ContactForm