import Style from './PrivacyPolicy.module.css'

const PrivacyPolicy = () => {
  return ( 
    <section className={Style.privacyPolicy}>
      <div className="container">
        <h1>Willow & I Privacy Policy</h1>
        <section>
          <p>This Privacy Policy is delivered on behalf of Willow & I, and it governs the personal information we collect or receive from or about you when you visit our website or engage with our services, whether online or offline (collectively, the “Services”).</p>
          <p>At Willow & I, we are committed to protecting the privacy of our customers and visitors. This Privacy Policy explains our practices for collecting, sharing, and using personal information and outlines your choices regarding how your information is collected, used, and shared. This policy does not apply to third-party services, websites, or applications accessed through our Services.</p>
          <p>If you have any questions or concerns about our Privacy Policy or our practices regarding your information, please contact us at <a href="mailto:hello@willowandi.co">hello@willowandi.co</a>.</p>
        </section>
        <section>
          <h2>Information We Collect</h2>
          <p>We collect information about you in various ways and from various sources. You may provide personal information directly to us, we may collect certain information automatically through your use of the Services, or we may obtain information about you from other sources.</p>
        </section>
        <section>
          <h2>Information You Provide To Us</h2>
          <p>When you use our Services, you may order products, subscribe to our mailing lists, participate in promotions, register for an account, or communicate with us. The information we collect may include your name, address, email address, phone number, date of birth, or payment information as necessary for completing a purchase.</p>
        </section>
        <section>
          <h2>Information We Collect Automatically</h2>
          <p>We, our partners, advertisers, or service providers may automatically collect information when you visit our website or use our Services. This may include:</p>
          <ul>
            <li>The type of browser and operating system you are using</li>
            <li>Your IP address or mobile device identifier</li>
            <li>The date and time of your visit and information about the content you view</li>
            <li>Location-based information regarding your access to our Services</li>
            <li>Information about your interactions with advertisements</li>
          </ul>
        </section>
        <section>
          <h2>Information We Obtain From Other Sources</h2>
          <p>We may also collect information about you from third-party sources, including social media and your employer if applicable.</p>
        </section>
        <section>
          <h2>How We Use the Information</h2>
          <p>We may use the information collected to:</p>
          <ul>
            <li>Provide and operate our Services</li>
            <li>Process payments and fulfill orders</li>
            <li>Communicate with you about your orders or inquiries</li>
            <li>Improve our Services and personalize your experience</li>
            <li>Market our products and services that may interest you</li>
            <li>Conduct research and analytics</li>
            <li>Comply with legal obligations</li>
          </ul>
        </section>
        <section>
          <h2>How We Share the Information</h2>
          <p>We may share the information we collect with:</p>
          <ul>
            <li>Our affiliates</li>
            <li>Service providers and vendors who assist us in providing the Services</li>
            <li>Third parties for analytics and advertising purposes</li>
            <li>Legal authorities when required by law or to protect our rights</li>
          </ul>
        </section>
        <section>
          <h2>Online Tracking and Advertising</h2>
          <p>We and our service providers may use cookies and similar tracking technologies to collect information about your use of our Services. You may manage your cookie preferences through your browser settings.</p>
        </section>
        <section>
          <h2>Data Retention</h2>
          <p>We will retain your personal information only as long as necessary to fulfill the purposes for which it was collected and to comply with applicable laws.</p>
        </section>
        <section>
          <h2>Removing or Updating Personal Information</h2>
          <p>If you believe your personal information is inaccurate or if you would like us to delete your information, please contact us at <a href="mailto:hello@willowandi.co">hello@willowandi.co</a>.</p>
        </section>
        <section>
          <h2>Security</h2>
          <p>We have implemented reasonable security measures to protect your personal information, but we cannot guarantee complete security.</p>
        </section>
        <section>
          <h2>Children’s Privacy</h2>
          <p>Our Services are not intended for users under the age of 18. We do not knowingly collect information from anyone under this age. If you believe we have collected information from a child, please contact us.</p>
        </section>
        <section>
          <h2>Non-U.S. Visitors</h2>
          <p>Any information we obtain about you in connection with your use of the Services may be processed and stored in the United States or other countries.</p>
        </section>
        <section>
          <h2>Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time. The revised Privacy Policy will be posted on our website, and we encourage you to review it periodically.</p>
        </section>
        <section>
          <h2>Contact Us</h2>
          <address>
            <p>For any questions or concerns, please reach us at:</p>
            <p>Willow & I<br />5432 Geary Blvd, Unit #901<br />San Francisco, CA 94121<br/>Email: <a href="mailto:hello@willowandi.co">hello@willowandi.co</a></p>
          </address>
        </section>
      </div>
    </section>
   );
}
 
export default PrivacyPolicy;